/*! #######################################*/
/*! ############### MOBILE ###############*/
/*! #######################################*/
.logoBapps{
    width: 70px;
    height: auto;
}

/** #### Estilos generales #### */
*{
    box-sizing: border-box;
}
html{
    width: 100%;
}
body {
    font-family: Poppins, serif;
    font-size: 12px;
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    width: 100%;
    overflow-x: hidden;
}
.cover-image-shadowed,
.introduction-image{
    box-shadow: 0px 4px 56px 2px rgba(0, 0, 0, 0.25);
    object-fit: cover;
}
.box-shadow{
    box-shadow: 0px 4px 56px 2px rgba(0, 0, 0, 0.25);
}
.h-500px{
    height: 500px;
}
.h-600px{
    height: 600px;
}
.w-30percent{
    width: 30%;
}

.fs-09rem{
    font-size: 0.9rem;
}
.fs-1rem{
    font-size: 1rem;
}
.fs-11rem{
    font-size: 1.1rem;
}
.fs-13rem{
    font-size: 1.3rem;
}
.fs-15rem{
    font-size: 1.5rem;
}
.fs-18rem{
    font-size: 1.8rem;
}
.fs-2rem{
    font-size: 2rem;
}
.fs-25rem{
    font-size: 2.5rem;
}
.fs-26rem{
    font-size: 2.6rem;
}
.fs-3rem{
    font-size: 3rem;
}
.fs-35rem{
    font-size: 3.5rem;
}
.fs-4rem{
    font-size: 4rem;
}
.fs-45rem{
    font-size: 4.5rem;
}
.fs-5rem{
    font-size: 5rem;
}
.fs-55rem{
    font-size: 5.5rem;
}
.fs-6rem{
    font-size: 6rem;
}
.fs-7rem{
    font-size: 7rem;
}
.fw-semibold{
    font-weight: semi-bold;
}
.fw-lighter{
    font-weight: lighter;
}
.py-30px{
    padding-top: 30px;
    padding-bottom: 30px;
}
.py-35px{
    padding-top: 35px;
    padding-bottom: 35px;
}
.py-55px{
    padding-top: 55px;
    padding-bottom: 55px;
}
.py-50px{
    padding-top: 50px;
    padding-bottom: 50px;
}

.lh-chico{
    line-height: 1;
}

.texto-elegante{
    font-family: Playfair Display, serif;
}
.lh-chico{
    line-height: 1;
}
.min-height-120{
    min-height: 120 !important;
}
.min-height-125{
    min-height: 125 !important;
}
.min-height-250{
    min-height: 250px !important;
}
.min-height-300{
    min-height: 300px !important;
}
.min-height-530{
    min-height: 530px !important;
}
.min-height-600{
    min-height: 660px !important;
}

footer .footerAccordion .accordion {
    --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='white'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
    --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='white'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}
footer .footerAccordion .accordion-button.active::after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23fff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
}

.bg-green-la-cheminee{
    background-color: #394C02;
}
.texto-verde-la-cheminee{
    color: #394C02;
}

.border-cheminee-start{
    border-left: 2px solid #394C02;
}
.rounded-10px{
    border-radius: 10px !important;
}
.rounded-15px{
    border-radius: 15px;
}
.rounded-10px{
    border-radius: 10px;
}
.border-cheminee-end{
    border-right: 1px solid #394C02;
}
.btn-la-cheminee{
    color: white;
    text-align: center;
    text-decoration: none;
    font-size: 1rem;

    border-radius: 10px;
    padding: 3% 5%;
    border: #394C02 2px solid !important;
    transition: all 0.3s ease-in-out;
}

.z-index-150{
    z-index: 200;
}

.top-separator{
    border-top: 0.5px solid #394C02;
}

.h-fit-content{
    height: fit-content;
}

.h-900px{
    height: 900px;
}

/*! ########################################*/
/*! ##### TABLETS & LARGER SMARTPHONES #####*/
/*! ########################################*/
@media (min-width: 481px) {
    .logoBapps{
        width: 80px;
        height: auto;
    }

    .btn-la-cheminee{
        border-radius: 10px;
        padding: 2% 0%;
    }

    .fs-md-3rem{
        font-size: 3rem;
    }
    .fs-md-1rem{
        font-size: 1rem;
    }
    .fs-md-13rem{
        font-size: 1.3rem;
    }
    .fs-md-15rem{
        font-size: 1.5rem;
    }
    .fs-md-2rem{
        font-size: 2rem;
    }
    .fs-md-25rem{
        font-size: 2.5rem;
    }

    .fs-md-4rem{
        font-size: 4rem;
    }

    .h-md-100{
        height: 100%;
    }
    .min-height-md-185{
        min-height: 185px !important;
    }
    .min-height-md-190{
        min-height: 190px !important;
    }
    .min-height-md-380{
        min-height: 380px !important;
    }
    .min-height-md-480{
        min-height: 480px !important;
    }
    .min-height-md-700{
        min-height: 750px !important;
    }

    .py-md-40px{
        padding-top: 40px;
        padding-bottom: 40px;
    }
    .py-md-45px{
        padding-top: 45px;
        padding-bottom: 45px;
    }

    .h-md-400px{
        height: 400px;
    }
    .rounded-md-15px{
        border-radius: 15px !important;
    }
}


/*! ########################################*/
/*! ################ LAPTOPS ###############*/
/*! ########################################*/
@media (min-width: 769px) {


    .btn-la-cheminee{
        border-radius: 15px;
        padding: 2% 0%;
    }

    .btn-la-cheminee:hover{
        color: #394C02;
        background-color: white;
        font-weight: bold;
    }

    .fs-lg-1rem{
        font-size: 1rem;
    }
    .fs-lg-2rem{
        font-size: 2rem;
    }
    .fs-lg-25rem{
        font-size: 2.5rem;
    }
    .fs-lg-3rem{
        font-size: 3rem;
    }
    .fs-lg-45rem{
        font-size: 4.5rem;
    }
    .fs-lg-5rem{
        font-size: 5rem;
    }
    .fs-lg-7rem{
        font-size: 7rem;
    }

    .py-lg-50px{
        padding-top: 50px !important;
        padding-bottom: 50px !important;
    }
    .py-lg-55px{
        padding-top: 55px;
        padding-bottom: 55px;
    }

    .h-lg-600px{
        height: 600px;
    }
    .border-bottom-lg-0 {
        border-bottom: none !important;
    }

    .min-height-lg-300{
        min-height: 300px !important;
    }

    .min-height-lg-600{
        min-height: 600px !important;
    }
}

/*! ########################################*/
/*! ############ LARGER DESKTOPS ###########*/
/*! ########################################*/
@media (min-width: 1280px) {
    .btn-la-cheminee{
        border-radius: 20px;
    }
    .fs-xl-7rem{
        font-size: 7rem;
    }
    .fs-xl-15rem{
        font-size: 1.5rem;
    }
    .fs-xl-2rem{
        font-size: 2rem;
    }
    .fs-xl-25rem{
        font-size: 2.5rem;
    }
    .fs-xl-3rem{
        font-size: 3rem;
    }
    .fs-xl-35rem{
        font-size: 3.5rem;
    }

    .py-lg-60px{
        padding-top: 60px;
        padding-bottom: 60px;
    }
    .py-lg-65px{
        padding-top: 65px;
        padding-bottom: 65px;
    }

    .h-xl-800px{
        height: 900px;
    }

    .h-xl-900px{
        height: 900px;
    }

    .min-height-xl-450{
        min-height: 450px !important;
    }

    .min-height-xl-700{
        min-height: 700px !important;
    }
}